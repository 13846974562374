import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIsShowingFeedbackForm } from '../../Store/Actions/app'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import ForturnCookies from '../FortuneCookies/FortuneCookies'
import { appInsights } from '../../App'
import './Header.css'

const Header: React.FC = () => {
    const dispatch = useDispatch()
    const [isShowingTutorial, setIsShowingTutorial] = useState(false)
    const isShowingFeedbackForm = useSelector((state:any) => state.app.isShowingFeedbackForm)

    return (
        <div className="header">
            <div className="header--left">
                <span className="app-name" ><a href='/' rel='home' className="team-calendar">Team Calendar</a> | </span><span className="app-detail">Collaborate happens here</span>
            </div>
            <div className="header--right">
                <button className="em-c-btn em-c-btn--white em-u-margin-right" onClick={() => setIsShowingTutorial(true)}>Tutorial</button>
                <button className="em-c-btn em-c-btn--white" onClick={() => {
                    appInsights.trackEvent({name: 'clickFeedback'});
                    dispatch(updateIsShowingFeedbackForm(true))}
                }>Feedback</button>
            </div>
            {isShowingTutorial ? <ForturnCookies onClose={() => setIsShowingTutorial(false)} /> : null}
            {isShowingFeedbackForm ? <FeedbackForm onClose={() => dispatch(updateIsShowingFeedbackForm(false))} /> : null}
        </div>
    )
}

export default Header
