import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import UserCard from "../UserCard/UserCard";
import { clearUserList, updateUserList } from "../../Store/Actions/user";
import "./MemberForm.css";
import AlertMessage from "../AlertMessage/AlertMessage";
import { RoleTypes } from "../../Utilities/ConstRoles";
import Icon from "../Icons/Icon";

interface IMemberForm {
  teamId: string;
  members: any;
  logonUserRole: RoleTypes;
  handleMembersOnChange: Function;
  isEditing: boolean;
  setIsEditing: Function;
}

const MemberForm: React.FC<IMemberForm> = ({
  teamId,
  members,
  logonUserRole,
  handleMembersOnChange,
  isEditing,
  setIsEditing,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [showWarning, setShowWarning] = useState<any>(null);
  const userList = useSelector((state: any) => state.user.userList);
  const isUserNotFound = useSelector((state: any) => state.user.isUserNotFound);
  const logonUser = useSelector((state: any) => state.user);

  const emailInputCallback = (value: string) => {
    dispatch(clearUserList());
    setEmailInput(value);
    if (value.length >= 3) {
      setIsLoading(true);
      dispatch(updateUserList(value));
    }
  };

  const findUserIndex = (user: any) => {
    return members.findIndex((member) => member.userId === user.userId);
  };
  
  const handleOnSubteamChange = (user, subteam) => {
    let memberIndex = findUserIndex(user);
    let updatedMembers = [...members];
    updatedMembers[memberIndex].subteam = subteam;
    handleMembersOnChange(updatedMembers);
  };

  const handleOnRoleChange = (user: any, roleId: RoleTypes) => {
    if (roleId === RoleTypes.Owner) {
      setShowWarning({
        message: `Are you sure you want to promote '${user.username}' to team owner?`,
        subMessage: `Team owner will change when press 'confirm' and you will be admin role instead, if you in the team memberships.`,
        firstBtnName: "Confirm",
        firstBtnCallback: () => confirmChangeOwnerCallback(user),
        secondBtnName: "Cancel",
        secondBtnCallback: () => setShowWarning(null),
      });
    } else {
      let memberIdx = findUserIndex(user);
      let updatedMembers = [...members];
      updatedMembers[memberIdx].roleId = roleId;
      handleMembersOnChange(updatedMembers);
    }
  };

  const confirmChangeOwnerCallback = (user: any) => {
    let updatedMembers = [...members];
    let oldOwner = members.find((member) => member.roleId === RoleTypes.Owner);
    if (oldOwner !== undefined) {
      oldOwner.roleId = RoleTypes.Admin;
    }
    let memberIndex = findUserIndex(user);
    updatedMembers[memberIndex].roleId = RoleTypes.Owner;
    handleMembersOnChange(updatedMembers);
    setShowWarning(null);
  };
  
  const addMemberCallback = (user: any) => {
    setEmailInput("");
    dispatch(clearUserList());
    members.push({
      userId: user.id,
      username: user.name,
      teamId: teamId,
      subteam: "",
      roleId: logonUser.userId === user.id ? RoleTypes.Owner : RoleTypes.Member,
    });
  };

  const removeMemberCallback = (user: any) => {
    let memberIndex = findUserIndex(user);
    let updatedMembers = [...members];
    updatedMembers.splice(memberIndex, 1);
    handleMembersOnChange(updatedMembers);
    setShowWarning(null);
  };

  const updateWarningMessage = (
    message: string,
    subMessage: string,
    confirmCallback: Function
  ) => {
    setShowWarning({
      message,
      subMessage,
      firstBtnName: "Confirm",
      firstBtnCallback: confirmCallback,
      secondBtnName: "Cancel",
      secondBtnCallback: () => setShowWarning(null),
    });
  };

  useEffect(() => {
    if (userList !== null) {
      setIsLoading(false);
      if (emailInput.length < 3) {
        dispatch(clearUserList());
      }
    }
  }, [userList]);

  return (
    <div className="member-form">
      <div className="em-c-table-object__header">
        <div className="em-c-search__body">
          <input
            type="search"
            id="search"
            className="em-c-search__input"
            placeholder="Search to find your colleague"
            value={emailInput}
            onChange={(e) => emailInputCallback(e.target.value)}
          />
          {isLoading && (
            <LoadingIcon optionalClass="member-form__body--search-loading" />
          )}
        </div>
      </div>
      {emailInput.length >= 3 && isUserNotFound && (
        <div className="member-form__user-picker no-data">
          We could not find the user in the system.
        </div>
      )}
      {userList && (
        <div className="member-form__user-picker">
          <div className="member-form__user-picker--list">
            {userList.map((user) => (
              <UserCard
                key={user.id}
                user={user}
                callback={() => addMemberCallback(user)}
              />
            ))}
          </div>
        </div>
      )}
      {members && (
        <table className="em-c-table em-u-margin-top-double em-u-margin-bottom-double">
          <thead className="em-c-table__header">
            <tr className="em-c-table__header-row">
              <th scope="col" className="em-c-table__header-cell">
                Name
              </th>
              <th scope="col" className="em-c-table__header-cell">
                Roles
              </th>
              <th scope="col" className="em-c-table__header-cell">
                Subteam
              </th>
              <th scope="col" className="em-c-table__header-cell em-u-width-10">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="em-c-table__body ">
            {members.map((member) => (
              <tr key={member.userId} className="em-c-table__row ">
                <td className="em-c-table__cell em-js-cell">
                  {member.username}
                </td>
                {isEditing && (
                  <>
                    <td className="em-c-table-select em-js-select">
                      <select
                        disabled={
                          member.roleId === RoleTypes.Owner || //owner
                          logonUser.userId === member.userId //urself
                        }
                        value={member.roleId}
                        onChange={(e) =>
                          handleOnRoleChange(member, Number(e.target.value))
                        }
                      >
                        <option value="" disabled>
                          Select role type
                        </option>
                        {Object.values(RoleTypes)
                          .filter((role) => isNaN(Number(role)))
                          .map((index) => (
                            <option
                              defaultValue={member.roleId}
                              disabled={
                                (teamId === null ||
                                  logonUserRole !== RoleTypes.Owner) &&
                                RoleTypes[index] === RoleTypes.Owner
                              }
                              key={index}
                              value={RoleTypes[index]}
                            >
                              {index}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="em-c-table__cell em-js-cell">
                      <input
                        type="string"
                        className="member-form__subteam--input"
                        value={member.subteam}
                        placeholder="Subteam"
                        onChange={(e) =>
                          handleOnSubteamChange(member, e.target.value)
                        }
                      />
                    </td>
                    <td className="em-c-table__cell em-js-cell icon">
                      <Icon
                        icon="trashcan"
                        disabled={
                          member.roleId === RoleTypes.Owner &&
                          logonUserRole !== RoleTypes.Owner
                        }
                        optionalClass="member-form__icon em-c-icon em-c-icon--small"
                        callback={() =>
                          updateWarningMessage(
                            `Are you sure you want to delete '${member.username}' from your team?`,
                            `This member will be remove when press 'confirm'.`,
                            () => removeMemberCallback(member)
                          )
                        }
                      />
                    </td>
                  </>
                )}
                {!isEditing && (
                  <>
                    <td className="em-c-table__cell em-js-cell">
                      {RoleTypes[member.roleId]}
                    </td>
                    <td className="em-c-table__cell em-js-cell">
                      {member.subteam === "" ? "-" : member.subteam}
                    </td>
                    <td className="em-c-table__cell em-js-cell">
                      {(logonUserRole === RoleTypes.Owner ||
                        logonUserRole === RoleTypes.Admin) && (
                        <Icon
                          icon={`pencil`}
                          optionalClass="member-form__icon em-c-icon em-c-icon--small"
                          callback={() => {
                            setIsEditing(true);
                          }}
                        />
                      )}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
          <tfoot className="em-c-table__footer">
            <tr className="em-c-table__footer-row"></tr>
          </tfoot>
        </table>
      )}
      {showWarning !== null && (
        <AlertMessage
          message={showWarning.message}
          subMessage={showWarning.subMessage}
          firstBtnName={showWarning.firstBtnName}
          firstBtnCallback={showWarning.firstBtnCallback}
          secondBtnName={showWarning.secondBtnName}
          secondBtnCallback={showWarning.secondBtnCallback}
        />
      )}
    </div>
  );
};

export default MemberForm;
