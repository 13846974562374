import UserService from '../../Services/UserServices/UserService'

let controller: AbortController
let signal: AbortSignal

export const updateLogonUser = (info:any) => (dispatch:any) => {
    dispatch({ type: 'UPDATE_LOGON_USER', payload: info})
}

export const updateUserNotFound = (value: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: 'UPDATE_IS_USER_NOT_FOUND', payload: value })
    }
}

export const updateUserList = (value: string) => {
    if (controller !== undefined) {
        controller.abort();
    }
    controller = new AbortController()
    signal = controller.signal
    const request = UserService.getUsersAsync(value, signal)
    return (dispatch: any) => {
        request.then((response: any) => {
            if (response.length !== 0) {
                let results: Array<any> = response.map((user: any) => {
                    return {
                        id: user.id,
                        name: user.displayName,
                        email: user.mail,
                        lanId: user.extension_d6448b80620845bf89c63bb6933c28da_XOM_FullLANID
                    }
                })
                dispatch({ type: 'UPDATE_USER_LIST', payload: results })
                dispatch(updateUserNotFound(false))
            } else {
                dispatch({ type: 'UPDATE_USER_LIST', payload: [] })
                dispatch(updateUserNotFound(true))
            }
        }).catch(async(error) => {
            console.log(error)
        })
    }
}

export const updateSelectedUser = (user:any, subteam:string, roleId:number) => {
    return (dispatch: any) => {
        dispatch({ type: 'UPDATE_SELECTED_USER', payload: {...user, subteam, roleId}})
    }
}

export const clearUserList = () => {
    return (dispatch: any) => {
        dispatch({ type: 'CLEAR_USER_LIST' })
    }
}

