import store from "../../Store/store";

export default class EventService {
  static async getEvents(month: string, year: string, signal: AbortSignal) {
    const token = store.getState().user.token;
    let dateCondition = month ? `month=${month}` : "";
    dateCondition += year ? `&year=${year}` : "";
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/GetEvents?${dateCondition}`,
      {
        method: "GET",
        cache: "no-cache",
        signal: signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) {
      throw response;
    }
    return await response.json();
  }

  static async getLogonUserEvents(month: string, year: string) {
    const token = store.getState().user.token;
    let dateCondition = month ? `month=${month}` : "";
    dateCondition += year ? `&year=${year}` : "";
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/GetLogonUserEvents?${dateCondition}`,
      {
        method: "GET",
        cache: "no-cache",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) {
      throw response;
    }
    return await response.json();
  }

  static async createEvent(payload: any) {
    const token = store.getState().user.token;
    let response = await fetch(`${process.env.REACT_APP_API_URL}/CreateEvent`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (response.status !== 200) {
      throw response;
    }
    return { status: 200, message: "Event created successfully" };
  }

  static async createBulkEvent(payload: any) {
    const token = store.getState().user.token;
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/CreateBulkEvent`,
      {
        method: "POST",
        cache: "no-cache",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    if (response.status !== 200) {
      throw response;
    }
    return { status: 200, message: "Events created successfully" };
  }

  static async removeEvents(eventId: string) {
    const token = store.getState().user.token;
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/RemoveEvent?eventId=${eventId}`,
      {
        method: "DELETE",
        cache: "no-cache",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) {
      throw response;
    }
    return { status: 200, message: "Event removed successfully" };
  }
}
