interface IState {
    eventsOfOwnTeams: any
    eventsOfAdditionalTeams: any
} 

const initState: IState = {
    eventsOfOwnTeams: null,
    eventsOfAdditionalTeams: null
}

export default (state: IState = initState, action: any) => {
    switch (action.type) {
        case 'UPDATE_TEAM_EVENTS':
            return { ...state, eventsOfOwnTeams: action.payload.ownTeams, eventsOfAdditionalTeams: action.payload.additionalTeams }
        case 'CLEAR_STORE':
            return initState
        default:
            return state
    }
}
