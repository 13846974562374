import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeTeam, updateSelectedTeam } from "../../Store/Actions/team";
import { updateIsBtnLoading } from "../../Store/Actions/app";
import AlertMessage from "../AlertMessage/AlertMessage";
import { appInsights } from "../../App";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import { RoleTypes } from "../../Utilities/ConstRoles";
import "./TeamForm.css";

const TeamManageForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ownTeams, additionalTeams } = useSelector((state: any) => state.team);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const logonUser = useSelector((state: any) => state.user);

  const handleRemoveTeam = () => {
    appInsights.trackEvent({ name: "deleteTeam" });
    appInsights.startTrackEvent("deleteTeamDuration");
    dispatch(updateIsBtnLoading(true));
    dispatch(removeTeam(selectedTeam.team, selectedTeam.type));
  };

  const handleTeamOnClick = (team: any, path: string) => {
    dispatch(updateSelectedTeam(team));
    navigate(`/teams/${path}/${team.teamId}`);
  };

  useEffect(() => {
    dispatch(updateSelectedTeam(null));
  }, []);

  const displayRow = (team: any) => {
    const roleId = team?.memberships?.find(
      (member) => member.userId === logonUser.userId
    )?.roleId;
    return {
      roleId,
      component: (
        <tr key={team.teamId} className="em-c-table__row ">
          <td
            className="em-c-table__cell em-js-cell clickable"
            onClick={() =>
              handleTeamOnClick(
                team,
                roleId === RoleTypes.Admin || roleId === RoleTypes.Owner
                  ? "manage"
                  : "detail"
              )
            }
          >
            {team.teamName}
          </td>
          <td className="em-c-table__cell em-js-cell">
            {RoleTypes[roleId] || "Additional Team"}
          </td>
          <td className="em-c-table__cell em-js-cell icon">
            <svg
              className="team-form__icon em-c-icon em-c-icon--small"
              onClick={() =>
                setSelectedTeam({
                  team,
                  type: roleId === RoleTypes.Owner ? "Own" : "Additional",
                })
              }
            >
              {(roleId === RoleTypes.Owner || roleId === undefined) && (
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  href={`${icons}#trashcan`}
                ></use>
              )}
            </svg>
          </td>
        </tr>
      ),
    };
  };

  return (
    <div className="team-form--content">
      <table className="em-c-table">
        <thead className="em-c-table__header">
          <tr className="em-c-table__header-row">
            <th scope="col" className="em-c-table__header-cell">
              Team Name
            </th>
            <th scope="col" className="em-c-table__header-cell">
              Role
            </th>
            <th scope="col" className="em-c-table__header-cell em-u-width-10">
              Action
            </th>
          </tr>
        </thead>
        {ownTeams &&
        additionalTeams &&
        ownTeams.length === 0 &&
        additionalTeams.length === 0 ? (
          <tbody className="em-c-table__body">
            <tr className="em-c-table__row ">
              <td className="em-c-table__cell em-js-cell">No team data</td>
            </tr>
          </tbody>
        ) : (
          <tbody className="em-c-table__body ">
            {ownTeams &&
              additionalTeams &&
              [...ownTeams, ...additionalTeams]
                .map((team) => displayRow(team))
                .sort((a, b) => a.roleId - b.roleId)
                .map((row) => row.component)}
          </tbody>
        )}
        <tfoot className="em-c-table__footer">
          <tr className="em-c-table__footer-row"></tr>
        </tfoot>
      </table>
      {selectedTeam ? (
        <AlertMessage
          message={`Are you sure you want to remove '${
            selectedTeam.team.teamName
          }' team${
            selectedTeam.type === "Additional" ? " from your calendar view" : ""
          }?`}
          subMessage={`${
            selectedTeam.type === "Own"
              ? "This team will disappear from other calender view."
              : ""
          }`}
          firstBtnName="Confirm"
          firstBtnCallback={() => handleRemoveTeam()}
          secondBtnName="Cancel"
          secondBtnCallback={() => setSelectedTeam(null)}
        />
      ) : null}
    </div>
  );
};

export default TeamManageForm;
