import React from 'react'
import { fortuneCookieMsgs } from '../../Utilities/FortureMessages'
import './FortuneCookies.css'

interface IProps {
    onClose: Function
}

const ForturnCookies: React.FC<IProps> = ({ onClose }) => {
    const message = fortuneCookieMsgs[Math.floor(Math.random() * fortuneCookieMsgs.length)]
    return (
        <div className="overlay">
            <div className="fortune-cookies">
                <div className="cookie-img">
                    <img alt="cookies" src={cookie}/>
                </div>
                <div className="message">{message}</div>
                <div className="sub-message">
                    Sorry, we don't have tutorial yet... please play around and enjoy our fortune cookie!
                </div>
                <button className="em-c-btn em-c-btn--primary" onClick={() => onClose()}>Close</button>
            </div>
        </div>
    )
}

const cookie = require("../../Assets/images/fortune-cookies.png")

export default ForturnCookies
