import React, { useState } from "react";
import Icon from "../Icons/Icon";
import "./Dropdown.css";

export interface IOption {
  name: string;
  value: string | number;
}

interface IProps {
  title: string;
  icon: string;
  options: Array<IOption>;
  selectedOptions: Array<IOption>;
  onChangeCallback: Function;
}

const Dropdown: React.FC<IProps> = ({
  title, 
  icon,
  options,
  selectedOptions,
  onChangeCallback,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <div className="dropdown">
      <div
        className={`em-c-dropdown-check em-js-dropdown-check ${
          isActive ? "em-is-active" : ""
        } `}
      >
        <button
          className={`em-c-btn em-c-btn--is-dropdown em-js-dropdown-trigger em-c-btn--small ${
            isActive ? "em-is-active" : ""
          }`}
          type="button"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="em-c-btn__inner">
            <span className="em-c-btn__text em-u-font-size-med">{title}</span>
            <Icon icon={`${isActive ? "chevron-down-filled" : `${icon}`}`} />
          </div>
        </button>
        <div className="em-c-dropdown-check__panel" role="group">
          {options.length !== 0 ? (
            <ul className="em-c-dropdown-check__list">
              {options.map((option: IOption) => (
                <li className="em-c-dropdown-check__item" key={option.value}>
                  <label className="em-c-input-group em-c-checkbox--container">
                    <input
                      type="checkbox"
                      className="em-c-input-group__control"
                      value={`${option.value}`}
                      checked={
                        selectedOptions.find((o) => o.value === option.value)
                          ? true
                          : false
                      }
                      onChange={(e) => onChangeCallback(option)}
                    />
                    <span className="em-c-checkbox--checkmark"></span>
                    <span className="em-c-input-group__text">
                      {option.name}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <div className="em-u-text-align-center">
              Team not found!
            </div>
          )}
          <div className={`clear-icon ${isActive ? "active" : ""}`}>
            <button
              className={`em-c-btn__inner__text`}
              type="button"
              onClick={(e) => onChangeCallback(null)}
            >
              Clear
              <Icon icon="x-filled" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
