import { combineReducers } from 'redux'
import app from './app'
import team from './team'
import user from './user'
import event from './event'

export default combineReducers({
    app,
    team,
    user,
    event
})
