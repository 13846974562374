import React, { useState, useEffect } from "react";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import "./Button.css";

interface IProps {
  name: string;
  optionalClass?: string;
  callback: Function;
  hasLoading?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const Button: React.FC<IProps> = ({
  name,
  optionalClass,
  callback,
  hasLoading,
  loading,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => {
    setIsLoading(true);
    callback(name);
  };

  useEffect(() => {
    if (loading !== undefined) {
      setIsLoading(loading);
    }
  }, [loading]);

  return (
    <button
      className={`${optionalClass} em-c-btn ${
        disabled ? "em-c-btn--disabled" : ""
      }`}
      onClick={() => {
        hasLoading && (loading !== undefined ? loading : true)
          ? showLoading()
          : callback(name);
      }}
      disabled={disabled}
    >
      {isLoading ? <LoadingIcon /> : name}
    </button>
  );
};

export default Button;
