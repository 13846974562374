import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { createEvent } from "../../Store/Actions/event";
import { updateIsBtnLoading } from "../../Store/Actions/app";
import Button from "../Button/Button";
import Toggle from "../Button/Toggle";
import { appInsights } from "../../App";
import "./EventForm.css";
import { globalEvents } from "../../Utilities/ConstEvents";
import { getTeams } from "../../Store/Actions/team";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

interface IProps {
  onClose: Function;
  selectedDate: Date;
  userIds?: Array<string>;
  teamId?: string;
}

const EventForm: React.FC<IProps> = ({
  onClose,
  selectedDate,
  userIds,
  teamId,
}) => {
  const dispatch = useDispatch();
  const [inputRemark, setInputRemark] = useState("");
  const [isOneDayLeave, setIsOneDayLeave] = useState(true);
  const [oneDayChoice, setOneDayChoice] = useState<string>("full");
  const [startDate, setStartDate] = useState<Date | null>(selectedDate);
  const [endDate, setEndDate] = useState<Date | null>(selectedDate);
  const [currType, setCurrType] = useState<string | undefined>();
  const [selectedTeam, setSelectedTeam] = useState<Array<any>>([]);
  const [enableCreateButton, setEnableCreateButton] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isCustomIcon, setIsCustomIcon] = useState(false);

  const currMonth = useSelector((state: any) => state.app.currMonth);
  const teamEventInfo = useSelector((state: any) => state.app.teamEventInfos);
  const isBtnLoading = useSelector((state: any) => state.app.isBtnLoading);
  const events = useSelector((state: any) => state.user.events);
  const logonUserId = useSelector((state: any) => state.user.userId);
  const { ownTeams, additionalTeams } = useSelector((state: any) => state.team);

  const handleDateChange = (dates: any) => {
    const [start, end] = dates;
    if (!startDate) {
      setStartDate(start);
      setEndDate(start);
    } else if (
      moment(startDate).isSame(endDate, "date") &&
      moment(start).isSameOrAfter(startDate) &&
      moment(start).isSameOrAfter(endDate)
    ) {
      setEndDate(start);
    } else if (
      moment(startDate).isSame(endDate, "date") &&
      moment(start).isSameOrBefore(startDate)
    ) {
      setEndDate(startDate);
      setStartDate(start);
    } else {
      setStartDate(start);
      setEndDate(start);
    }
  };

  const createRecord = () => {
    appInsights.trackEvent({ name: "createRecord" });
    appInsights.startTrackEvent("createRecordDuration");
    dispatch(updateIsBtnLoading(true));
    if (startDate && endDate && currType) {
      dispatch(
        createEvent(
          startDate,
          endDate,
          currType,
          oneDayChoice,
          inputRemark,
          userIds,
          teamId
        )
      );
    }
  };

  const isDuplicatedEvent = () => {
    if (
      startDate &&
      endDate &&
      userIds?.length === 1 &&
      logonUserId === userIds?.[0]
    ) {
      return events?.some(
        (event) =>
          moment(startDate).isSameOrBefore(event.endDate, "date") &&
          moment(endDate).isSameOrAfter(event.startDate, "date")
      );
    } else {
      return false;
    }
  };

  const handleOnSelectedTeam = (teamId: string) => {
    let team = [...ownTeams, ...additionalTeams].find(
      (item) => item.teamId === teamId
    );
    if (team) setSelectedTeam(team.icons);
  };

  useEffect(() => {
    if (startDate && endDate) {
      if (currType && !isDuplicatedEvent()) {
        setEnableCreateButton(true);
      } else {
        setEnableCreateButton(false);
        setShowWarning(true);
      }
    }
    if (!startDate || !endDate) {
      return setEnableCreateButton(false);
    }
    return setShowWarning(false);
  }, [startDate, endDate, currType]);

  useEffect(() => {
    if (startDate === endDate) {
      setIsOneDayLeave(true);
      setOneDayChoice("full");
    } else setIsOneDayLeave(false);
  }, [startDate, endDate]);

  useEffect(() => {
    if (isCustomIcon && !(ownTeams || additionalTeams)) {
      dispatch(getTeams());
      dispatch(updateIsBtnLoading(true));
    }
  }, [isCustomIcon]);

  return (
    <div className="overlay">
      <div className="event-form">
        <h3>Add New Record</h3>
        <hr />
        <div className="date-picker">
          <DatePicker
            openToDate={new Date(currMonth)}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            selected={startDate}
            inline
          />
        </div>
        <div className="select-date">
          <div className="input-label">Date:</div>
          <div
            className={`select-date${
              showWarning && isDuplicatedEvent() ? "-warning" : ""
            }`}
          >
            {!startDate || !endDate
              ? "Please select date"
              : moment(startDate).isSame(moment(endDate))
              ? `${moment(startDate).format("L")}`
              : `${moment(startDate).format("L")} - ${moment(endDate).format(
                  "L"
                )}`}
            {showWarning && isDuplicatedEvent() ? (
              <div className="date-err">
                {" "}
                {startDate && endDate
                  ? "Selected date already has leave record"
                  : "Invalid date"}
              </div>
            ) : null}
          </div>
        </div>
        {isOneDayLeave ? (
          <div className="optional-questions--sub-questions">
            <div className="sub-question">
              <input
                type="radio"
                name="time"
                checked={oneDayChoice === "full"}
                onChange={() => setOneDayChoice("full")}
              />
              Full Day
            </div>
            <div className="sub-question">
              <input
                type="radio"
                name="time"
                onChange={() => setOneDayChoice("half-morning")}
              />
              Half Day - Morning
            </div>
            <div className="sub-question">
              <input
                type="radio"
                name="time"
                onChange={() => setOneDayChoice("half-evening")}
              />
              Half Day - Evening
            </div>
          </div>
        ) : null}
        <div className={`select-type em-c-field-custom`}>
          <div className="input-label-custom">Team Icon:</div>
          <Toggle
            id="team-icon-toggle"
            firstBtnName="Yes"
            firstBtnCallback={setIsCustomIcon}
            secondBtnName="No"
            secondBtnCallback={setIsCustomIcon}
            value={isCustomIcon}
            optionalClass="team-icon-toggle"
          />
          {isCustomIcon ? (
            <>
              <div className="input-label-team-select">Team: </div>
              <select
                className="em-c-select em-c-select-custom"
                defaultValue=""
                onChange={(e) => handleOnSelectedTeam(e.target.value)}
              >
                <option value="" disabled>
                  {isBtnLoading ? "Loading" : "Select team"}
                </option>
                {ownTeams &&
                  additionalTeams &&
                  [...ownTeams, ...additionalTeams]?.map((team) => (
                    <option key={team.teamId} value={team.teamId}>
                      {team.teamName}
                    </option>
                  ))}
              </select>
            </>
          ) : null}
        </div>

        <div
          className={`select-type em-c-field ${
            showWarning && !currType ? "em-has-error" : ""
          }`}
        >
          <div className="input-label">Type:</div>
          <select
            className="em-c-select em-c-select"
            defaultValue=""
            onChange={(e) => setCurrType(e.target.value)}
          >
            <option value="" disabled>
              Select leave type
            </option>
            {globalEvents.concat(selectedTeam).map((type) => {
              if (type.eventName)
                return (
                  <option key={type.eventName} value={type.eventId}>
                    {type.eventName}
                  </option>
                );
            })}
          </select>
        </div>

        <div className="remark">
          <div className="input-label">Remark: </div>
          <textarea
            value={inputRemark}
            onChange={(e) => setInputRemark(e.target.value)}
          />
        </div>
        <div className="button-wrapper">
          <Button
            name="Create record"
            optionalClass="em-c-btn em-c-btn--primary em-u-margin-right"
            callback={() =>
              enableCreateButton ? createRecord() : setShowWarning(true)
            }
            hasLoading={true}
            loading={
              enableCreateButton && isDuplicatedEvent() ? false : isBtnLoading
            }
          />
          <button
            className="em-c-btn em-c-btn--white"
            onClick={() => onClose()}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
