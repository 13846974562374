import React from "react";

interface IProps {
  text: string;
  children: any;
  optionalClass?: string;
}

const Tooltip: React.FC<IProps> = ({children, text, optionalClass}) => {
  return (
    <span className={`em-c-tooltip em-js-tooltip ${optionalClass}`} role="tooltip">
      <span className="em-c-tooltip__trigger em-js-tooltip-trigger">
        <span className="em-c-text-passage ">
            {children}
        </span>
      </span>
      <span className="em-c-tooltip__content" role="tooltip" id="tooltipText">
        <p>{text}</p>
      </span>
    </span>
  );
};

export default Tooltip;
