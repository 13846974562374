import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getTeams } from '../../Store/Actions/team'
import { updateIsPageLoading } from '../../Store/Actions/app'
import TeamManageForm from '../../Components/TeamForm/TeamManageForm'
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator'
import { appInsights } from '../../App'
import './TeamManagement.css'

const TeamManagement: React.FC = () => {
    const dispatch = useDispatch()
    const isPageLoading = useSelector((state:any) => state.app.isPageLoading)

    useEffect(() => {
        appInsights.trackPageView({name: 'usersAccessManageTeamView'});
        dispatch(updateIsPageLoading(true))
        dispatch(getTeams())
    }, [])
 
    return (
        <div className="app-content team-mngt">
            <h3>What do you want to do?</h3>
            <hr />
            <div className="team-mngt--btn-wrapper">
                <Link to="/teams/new"><button className="em-c-btn em-c-btn--white">Create new team</button></Link><br />
                <Link to="/teams/add"><button className="em-c-btn em-c-btn--white">Add other teams</button></Link><br />
            </div>
            <br /><br /><br />
            <h3>Teams</h3>
            <hr />
            {isPageLoading ? <LoadingIndicator optionalClass="team-mngt--loading"/> : <TeamManageForm /> }
        </div>

    )
}

export default TeamManagement
