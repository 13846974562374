import moment from 'moment'
import EventService from "../../Services/EventServices/EventService"
import { appInsights } from '../../App'
import { updateErrMessage, updateIsBtnLoading, updateIsPageLoading } from './app'

let controller: AbortController
let signal: AbortSignal

export const createEvent = (startDate: Date, endDate: Date, eventTypeId: string, oneDayChoice?: string | null, remark?: string | null, userIds?: Array<string> | undefined, teamId?: string) => {
    return (dispatch: any) => {
        let payload = {
            startDate: moment(startDate).format('LL'),
            endDate: moment(endDate).format('LL'),
            eventTypeId,
            oneDayChoice: oneDayChoice ?? null,
            remark: remark ?? null,
            teamId: teamId,
        }
        if(userIds?.length === 1 || userIds === undefined ){
            if(userIds?.length === 1){ payload['userId'] = userIds[0] }
            const request = EventService.createEvent(payload)
            request.then((response: any) => {
                appInsights.stopTrackEvent('createRecordDuration')
                window.location.reload()
            }).catch(async(error) => {
                const response = await error.json()
                dispatch(updateIsBtnLoading(false))
                dispatch(updateErrMessage(response.message))
                appInsights.stopTrackEvent('createRecordDuration')
            })
        } else {
            payload['userIds'] = userIds
            const request = EventService.createBulkEvent(payload)
            request.then((response: any) => {
                appInsights.stopTrackEvent('createBulkRecordDuration')
                window.location.replace('/calendar')
            }).catch(async(error) => {
                const response = await error.json()
                dispatch(updateIsBtnLoading(false))
                dispatch(updateErrMessage(response.message))
                appInsights.stopTrackEvent('createBulkRecordDuration')
            })
        }
    }
}

export const removeEvent = (eventId: string) => {
    return (dispatch: any) => {
        const request = EventService.removeEvents(eventId)
        request.then((response: any) => {
            appInsights.stopTrackEvent('deleteRecordDuration')
            window.location.reload()
        }).catch(async (error) => {
            const response = await error.json()
            dispatch(updateIsBtnLoading(false))
            dispatch(updateErrMessage(response.message))
            appInsights.stopTrackEvent('deleteRecordDuration')
        })
    }
}

export const getLogonUserEvents = (date:any) => {
    const month = moment(date).format("MMM")
    const year = moment(date).format("YYYY")
    return (dispatch: any) => {
        const request = EventService.getLogonUserEvents(month, year)
        request.then((response: any) => {
            dispatch({ type: 'UPDATE_LOGON_USER_EVENTS', payload: response })
            appInsights.stopTrackEvent('getLogonUserEventsDuration')
        }).catch(async (error) => {
            console.log(error)
            dispatch(updateErrMessage(error))
            appInsights.stopTrackEvent('getLogonUserEventsDuration')
        })
    }
}

export const getEvents = (date:any) => {
    if (controller !== undefined) {
        controller.abort();
    }
    controller = new AbortController()
    signal = controller.signal
    const month = moment(date).format("MMM")
    const year = moment(date).format("YYYY")
    return (dispatch: any) => {
        const request = EventService.getEvents(month, year, signal)
        request.then((response: any) => {
            dispatch({ type: 'UPDATE_TEAM_EVENTS', payload: response })
            dispatch(updateIsPageLoading(false))
            appInsights.stopTrackEvent('getEventsDuration')
        }).catch(async (error) => {
            const response = await error.json()
            appInsights.stopTrackEvent('getEventsDuration')
            console.log(response.message)
        })
    }
}
