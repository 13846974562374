import React, { useState } from "react";
import EventForm from "../EventForm/EventForm";
import Button from "../Button/Button";
import "./BulkEventCreation.css";

interface IProps {
  team: any;
}

const BulkEventCreation: React.FC<IProps> = ({ team }) => {
  const [showingForm, setShowingForm] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<Array<any>>([]);
  const [currStep, setCurrStep] = useState<number>(0);

  const selectedMembersOnchange = (member: any) => {
    if (selectedMembers.includes(member)) {
      setSelectedMembers(selectedMembers.filter((item) => item !== member));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  return (
    <div className="bulk-creation">
      <Button
        optionalClass="em-c-btn--primary em-c-field"
        name="Bulk Event Creation"
        callback={() => {
          setShowingForm(true)
          setSelectedMembers([])
        }}
      />
      {showingForm && currStep === 0 && (
        <div className="overlay">
          <div className="membership">
            <h3>Select members to apply</h3>
            <span className="warning">
             
            </span>
            <hr />
            <div className="member-header">
              <h4>Membership</h4>
              <div className="em-u-display-flex">
                Select All
                  <label className="em-c-input-group em-c-checkbox--container">
                    <input
                      type="checkbox"
                      className="em-c-input-group__control"
                      onChange={() =>
                        setSelectedMembers(
                          selectedMembers.length === team.memberships.length
                            ? []
                            : team.memberships
                        )
                      }
                      checked={selectedMembers.length === team.memberships.length}
                    />
                    <span className="em-c-checkbox--checkmark"></span>
                  </label>
              </div>
            </div>
            <div className="member-list">
              {team.memberships.map((member: any) => (
                <div className="member-checkbox" key={member.userId}>
                  <div>{member.username}</div>
                  <label className="em-c-input-group em-c-checkbox--container">
                    <input
                      type="checkbox"
                      className="em-c-input-group__control"
                      onChange={() => selectedMembersOnchange(member)}
                      checked={selectedMembers.includes(member)}
                    />
                    <span className="em-c-checkbox--checkmark"></span>
                  </label>
                </div>
              ))}
            </div>
            <Button
              optionalClass="em-c-btn--primary em-u-margin-top"
              name="Next"
              callback={() => setCurrStep(1)}
            />
            <Button
              optionalClass="em-c-btn--white em-u-margin-left"
              name="Back"
              callback={() => setShowingForm(false)}
            />
          </div>
        </div>
      )}
      {showingForm && currStep === 1 && (
        <EventForm
          selectedDate={new Date()}
          onClose={() => {
            setShowingForm(false);
            setCurrStep(0);
          }}
          userIds={selectedMembers.map((member) => member.userId)}
          teamId={team.teamId}
        />
      )}
    </div>
  );
};

export default BulkEventCreation;
