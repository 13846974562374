import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getTeamDetail,
  newEmptyTeam,
  updateTeam,
} from "../../Store/Actions/team";
import {
  updateIsBtnLoading,
  updateIsPageLoading,
} from "../../Store/Actions/app";
import MemberForm from "../MemberForm/MemberForm";
import Toggle from "../Button/Toggle";
import Button from "../Button/Button";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { appInsights } from "../../App";
import "./TeamForm.css";
import AlertMessage from "../AlertMessage/AlertMessage";
import { RoleTypes } from "../../Utilities/ConstRoles";
import BulkEventCreation from "../BulkEventCreation/BulkEventCreation";
import TeamEventCreation from "../TeamEventCreation/TeamEventCreation";

const TeamCreatingForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();
  const [teamNameInput, setTeamNameInput] = useState("");
  const [isSearchable, setIsSearchable] = useState(true);
  const [isTeamChanged, setIsTeamChanged] = useState(false);
  const [editingMembers, setEditingMembers] = useState(false);
  const [warning, setWarning] = useState(false);
  const [modifiedMembers, setModifiedMembers] = useState<any>(null);
  const [modifiedIcons, setModifiedIcons] = useState<any>(undefined);
  const [logonUserRole, setLogonUserRole] = useState<RoleTypes>(
    RoleTypes.Member
  );
  const selectedTeam = useSelector((state: any) => state.team.selectedTeam);
  const isBtnLoading = useSelector((state: any) => state.app.isBtnLoading);
  const isPageLoading = useSelector((state: any) => state.app.isPageLoading);
  const logonUser = useSelector((state: any) => state.user);

  const saveTeam = () => {
    appInsights.trackEvent({
      name: selectedTeam.teamId ? "updateTeam" : "createNewTeam",
    });
    appInsights.startTrackEvent(
      selectedTeam.teamId ? "updateTeamDuration" : "createNewTeamDuration"
    );
    dispatch(updateIsBtnLoading(true));
    dispatch(
      updateTeam(
        teamNameInput,
        isSearchable,
        modifiedMembers,
        modifiedIcons,
        navigate
      )
    );
  };

  const updateDefaultTeam = () => {
    setTeamNameInput(selectedTeam.teamName);
    setIsSearchable(selectedTeam.isSearchable);
    setModifiedMembers(JSON.parse(JSON.stringify(selectedTeam.memberships)));
    setEditingMembers(false);
    setUserRole();
  };

  const setUserRole = () => {
    let role = selectedTeam.memberships.find(
      (member) => member.userId === logonUser.userId
    );
    if(role) setLogonUserRole(role.roleId);
  };

  useEffect(() => {
    if (
      selectedTeam &&
      (teamNameInput !== selectedTeam.teamName ||
        isSearchable !== selectedTeam.isSearchable ||
        JSON.stringify(modifiedMembers) !==
          JSON.stringify(selectedTeam.memberships) ||
        modifiedIcons?.some((icon: any) => {
          let existingIcon = selectedTeam.icons.find(
            (item) => item.eventIcon === icon.eventIcon
          );
          if (existingIcon) return existingIcon.eventName !== icon.eventName;
          return icon.eventName !== "" && existingIcon === undefined;
        }))
    ) {
      setIsTeamChanged(true);
    } else {
      setIsTeamChanged(false);
    }
  }, [teamNameInput, isSearchable, modifiedMembers, modifiedIcons]);

  useEffect(() => {
    appInsights.trackPageView({ name: "userAccessCreateTeamView" });
  }, []);

  useEffect(() => {
    if (teamId && !selectedTeam) {
      dispatch(updateIsPageLoading(true));
      dispatch(getTeamDetail(teamId));
    } else if (!teamId) {
      dispatch(newEmptyTeam());
    }
  }, [teamId]);

  useEffect(() => {
    if (
      selectedTeam &&
      selectedTeam.teamName !== "" &&
      selectedTeam.teamId !== null
    ) {
      updateDefaultTeam();
    } else {
      setTeamNameInput("");
      setModifiedMembers([
        {
          userId: logonUser.userId,
          username: logonUser.username,
          teamId: null,
          roleId: 1,
          subteam: "",
        },
      ]);
      setLogonUserRole(RoleTypes.Owner);
    }
  }, [selectedTeam]);

  return (
    <div className="app-content team-form">
      <h3>
        Manage Team{" "}
        <span className="sub-title">
          |{" "}
          {selectedTeam
            ? selectedTeam.teamId
              ? "Current team"
              : "Create new team"
            : ""}
        </span>
      </h3>
      <hr />
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        modifiedMembers && (
          <div className="team-form--content">
            <h5>Team Name</h5>
            <input
              disabled={logonUserRole !== RoleTypes.Owner}
              className={`em-c-field ${
                teamNameInput.length === 0 && selectedTeam?.teamId
                  ? "warning"
                  : ""
              }`}
              type="string"
              value={teamNameInput}
              placeholder="Enter your team name"
              onChange={(e) => setTeamNameInput(e.target.value)}
            />
            <h5>Searchable</h5>
            <Toggle
              id="searchable-toggle"
              firstBtnName="Yes"
              firstBtnCallback={setIsSearchable}
              secondBtnName="No"
              secondBtnCallback={setIsSearchable}
              value={isSearchable}
              note="Other user can search for this team to see calendar view"
              isDisabled={logonUserRole !== RoleTypes.Owner}
            />

            {(logonUserRole === RoleTypes.Owner ||
              logonUserRole === RoleTypes.Admin) &&
              selectedTeam.teamId && (
                <div>
                  <BulkEventCreation team={selectedTeam} />
                  <h5>Custom Event Types</h5>
                  <TeamEventCreation
                    teamIcons={selectedTeam.icons}
                    handleOnBackCallback={setModifiedIcons}
                  />
                </div>
              )}
            <h5>
              Membership
              {selectedTeam &&
                selectedTeam.memberships.length === 0 &&
                modifiedMembers.length === 0 && (
                  <span className="member-err">
                    Membership should not be empty
                  </span>
                )}
            </h5>
            <MemberForm
              teamId={selectedTeam.teamId}
              members={modifiedMembers}
              logonUserRole={logonUserRole}
              handleMembersOnChange={setModifiedMembers}
              isEditing={editingMembers}
              setIsEditing={setEditingMembers}
            />

            <div className="button-wrapper">
              <Button
                name={selectedTeam.teamId ? "Save" : "Create"}
                optionalClass="em-c-btn--primary em-u-margin-right"
                callback={() => saveTeam()}
                hasLoading={true}
                loading={isBtnLoading}
                disabled={teamNameInput === "" || modifiedMembers.length === 0}
              />
              <Button
                name={editingMembers ? `Cancel` : `Back`}
                callback={() =>
                  isTeamChanged
                    ? setWarning(true)
                    : editingMembers
                    ? setEditingMembers(false)
                    : navigate("/teams")
                }
                optionalClass="em-c-btn em-c-btn--white"
              />
              {isTeamChanged && selectedTeam?.teamId && (
                <span className="member-err">
                  The change has been made. Don't forget to 'Save'.
                </span>
              )}
            </div>
          </div>
        )
      )}
      {warning && (
        <AlertMessage
          message={`Are you sure you want to leave without saving?`}
          subMessage={`If you press 'Yes' the change will not be save`}
          firstBtnName="Yes"
          firstBtnCallback={() => {
            if (editingMembers) {
              updateDefaultTeam();
              setWarning(false);
            } else {
              navigate("/teams");
            }
          }}
          secondBtnName="No"
          secondBtnCallback={() => setWarning(false)}
        />
      )}
    </div>
  );
};

export default TeamCreatingForm;
