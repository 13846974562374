import moment from 'moment'

export interface IIcon {
    eventId: number | null
    eventName: string
    eventIcon: string
}

interface IState {
    currMonth: Date
    daysInMonth: number
    isShowingEventForm: boolean
    isShowingFeedbackForm: boolean
    isShowingEventDetail: boolean
    isBtnLoading: boolean
    isPageLoading: boolean
    errMessage: string | null
}

const initState: IState = {
    currMonth: new Date(),
    daysInMonth: moment().daysInMonth(),
    isShowingEventForm: false,
    isShowingFeedbackForm: false,
    isShowingEventDetail: false,
    isBtnLoading: false,
    isPageLoading: false,
    errMessage: null
}

export default (state: IState = initState, action: any) => {
    switch (action.type) {
        case 'UPDATE_CURR_MONTH':
            return { ...state, currMonth: action.payload, daysInMonth: moment(action.payload).daysInMonth() }
        case 'UPDATE_IS_SHOWING_EVENT_FORM':
            return { ...state, isShowingEventForm: action.payload }
        case 'UPDATE_IS_SHOWING_FEEDBACK_FORM':
            return { ...state, isShowingFeedbackForm: action.payload }
        case 'UPDATE_IS_SHOWING_EVENT_DETAIL':
            return { ...state, isShowingEventDetail: action.payload }    
        case 'UPDATE_IS_BUTTON_LOADING':
            return { ...state, isBtnLoading: action.payload }
        case 'UPDATE_IS_PAGE_LOADING':
            return { ...state, isPageLoading: action.payload } 
        case 'UPDATE_ERROR_MESSAGE':
            return { ...state, errMessage: action.payload } 
        case 'CLEAR_STORE':
            return initState
        default:
            return state
    }
}
