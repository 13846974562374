import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isWeekend } from "../../Utilities/DateUtility";
import {
  updateCurrMonth,
  updateIsPageLoading,
  updateIsShowingEventForm,
} from "../../Store/Actions/app";
import { getEvents, getLogonUserEvents } from "../../Store/Actions/event";
import TeamTable from "../../Components/TeamTable/TeamTable";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import Header from "../../Components/Header/Header";
import EventForm from "../../Components/EventForm/EventForm";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";
import EventDetail from "../../Components/EventDetail/EventDetail";
import { appInsights } from "../../App";
import "./Calendar.css";
import Dropdown, { IOption } from "../../Components/Dropdown/Dropdown";

const Calendar: React.FC = () => {
  const dispatch = useDispatch();
  const currMonth = useSelector((state: any) => state.app.currMonth);
  const daysInMonth = useSelector((state: any) => state.app.daysInMonth);
  const logonUserId = useSelector((state: any) => state.user.userId);
  const { eventsOfOwnTeams, eventsOfAdditionalTeams } = useSelector(
    (state: any) => state.event
  );
  const { userId, username, events } = useSelector((state: any) => state.user);
  const isPageLoading = useSelector((state: any) => state.app.isPageLoading);
  const isShowingEventForm = useSelector(
    (state: any) => state.app.isShowingEventForm
  );
  const isShowingEventDetail = useSelector(
    (state: any) => state.app.isShowingEventDetail
  );
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [filteredTeams, setFilteredTeams] = useState<Array<any>>([]);
  const ownerTeam = {
    teamName: "Owner",
    memberships: [{ userId, username, events }],
  };

  const checkIfWeekend = (idx: number) => {
    return isWeekend(moment(currMonth).set("date", idx + 1)) ? "weekend" : "";
  };

  const checkIfToday = (idx: number) => {
    return moment(currMonth)
      .set("date", idx + 1)
      .isSame(new Date(), "date")
      ? "today"
      : "";
  };

  const handleFilterTeamOnChange = (options: IOption) => {
    if (options === null) {
      setFilteredTeams([]);
    } else {
      let existingTeamFound = filteredTeams.find(
        (x) => x.teamId === options.value
      );
      if (existingTeamFound !== undefined) {
        let index = filteredTeams.findIndex(
          (x) => x.teamId === existingTeamFound.teamId
        );
        filteredTeams.splice(index, 1);
        setFilteredTeams([...filteredTeams]);
      } else {
        let selectedTeam = [
          ...eventsOfOwnTeams,
          ...eventsOfAdditionalTeams,
        ].find((x) => x.teamId === options.value);
        setFilteredTeams([...filteredTeams, selectedTeam]);
      }
    }
  };

  useEffect(() => {
    appInsights.trackPageView({ name: "usersAccessCalendarView" });
    appInsights.startTrackEvent("getLogonUserEventsDuration");
    appInsights.startTrackEvent("getEventsDuration");
    dispatch(updateIsPageLoading(true));
    dispatch(getLogonUserEvents(moment()));
    dispatch(getEvents(moment()));
  }, []);

  return (
    <div className="app-content">
      {isPageLoading ? (
        <LoadingIndicator optionalClass="content-loading" />
      ) : (
        eventsOfOwnTeams &&
        eventsOfAdditionalTeams && (
          <>
            <Header />
            <div className="calendar">
              <div className="calendar--header">
                <svg
                  className="em-c-icon em-c-icon--small"
                  onClick={() =>
                    dispatch(
                      updateCurrMonth(
                        moment(currMonth).subtract(1, "month").toDate()
                      )
                    )
                  }
                >
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    href={`${icons}#caret-left`}
                  ></use>
                </svg>
                <div className="calendar-wrapper">
                  <DatePicker
                    selected={currMonth}
                    onChange={(date) => dispatch(updateCurrMonth(date))}
                    dateFormat="MMMM"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                  <div className="year">{moment(currMonth).format("YYYY")}</div>
                </div>
                <svg
                  className="em-c-icon em-c-icon--small"
                  onClick={() =>
                    dispatch(
                      updateCurrMonth(
                        moment(currMonth).add(1, "month").toDate()
                      )
                    )
                  }
                >
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    href={`${icons}#caret-right`}
                  ></use>
                </svg>
                <Dropdown
                  title="Filter Team"
                  icon="filter"
                  options={[
                    ...eventsOfOwnTeams,
                    ...eventsOfAdditionalTeams,
                  ].map((team: any) => {
                    return { name: team.teamName, value: team.teamId };
                  })}
                  selectedOptions={
                    filteredTeams?.map((team) => {
                      return { name: team.teamName, value: team.teamId };
                    }) ?? []
                  }
                  onChangeCallback={handleFilterTeamOnChange}
                />
              </div>
              <div className="calendar--table-owner">
                <div className="table-header">
                  <div className="employee"></div>
                  <div className="dates">
                    {[...Array(daysInMonth)].map((e, i) => (
                      <div
                        key={i}
                        className={`dates-input ${checkIfWeekend(
                          i
                        )}${checkIfToday(i)}`}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="table-body">
                  <TeamTable
                    team={ownerTeam}
                    dateSelectCallback={(date) => setSelectedDate(date)}
                    eventSelectCallback={(event) => {
                      setSelectedEvent(event);
                    }}
                  />
                </div>
              </div>
              {(eventsOfOwnTeams.length > 0 ||
                eventsOfAdditionalTeams.length > 0) && (
                <div className="calendar--table">
                  <div className="table-header">
                    <div className="employee"></div>
                  </div>
                  <div className="table-body">
                    {(filteredTeams.length > 0
                      ? filteredTeams
                      : [...eventsOfOwnTeams, ...eventsOfAdditionalTeams]
                    ).map((team: any) => (
                      <TeamTable
                        key={team.teamName}
                        team={team}
                        dateSelectCallback={(date) => {
                          setSelectedDate(date);
                        }}
                        userSelectCallback={(userId) => {
                          setSelectedUserId(userId);
                        }}
                        teamSelectCallback={(team) => {
                          setSelectedTeam(team);
                        }}
                        eventSelectCallback={(event) => {
                          setSelectedEvent(event);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
              <br />
            </div>
          </>
        )
      )}
      {isShowingEventForm && (
        <EventForm
          selectedDate={selectedDate}
          onClose={() => dispatch(updateIsShowingEventForm(false))}
          userIds={selectedUserId ? [selectedUserId] : [logonUserId]}
          teamId={selectedTeam ? selectedTeam.teamId : undefined}
        />
      )}
      {isShowingEventDetail && selectedEvent && (
        <EventDetail
          eventDetail={selectedEvent}
          setSelectedEventCallback={setSelectedEvent}
          team={selectedTeam}
        />
      )}
    </div>
  );
};

export default Calendar;
