import React, { useEffect, useState } from "react";
import "./TeamEventCreation.css";
import { customEvents } from "../../Utilities/ConstEvents";

interface ITeamEvent {
  teamIcons: any;
  handleOnBackCallback: Function;
}

const TeamEventCreation: React.FC<ITeamEvent> = ({
  teamIcons,
  handleOnBackCallback,
}) => {
  const [icons, setIcons] = useState<Array<any>>([]);

  useEffect(() => {
    if (teamIcons) {
      const emptyIcons = JSON.parse(JSON.stringify(customEvents));
      const currIcons = emptyIcons.reduce((arr, item) => {
        const existingIcon = teamIcons.find(
          (icon) => icon.eventIcon === item.eventIcon
        );
        if (existingIcon) {
          return [...arr, existingIcon];
        }
        return [...arr, item];
      }, []);
      setIcons(JSON.parse(JSON.stringify(currIcons)));
    }
  }, [teamIcons]);

  const handleOnEventNameChange = (eventName:string, idx: number) => {
    const newIcons = [...icons];
    newIcons[idx].eventName = eventName;
    setIcons(newIcons);
    handleOnBackCallback(newIcons)
  };

  return (
    <div className="em-u-margin-bottom-double">
      <div className="em-l-grid em-l-grid--2up ">
        {icons.map((item: any, idx: number) => (
          <div
            className="em-l-grid__item"
            key={`${item.eventIcon}`}
          >
            <div className={`event ${item.eventIcon}`}></div>
            <input
              type="string"
              className="event-form-input"
              value={item.eventName}
              placeholder="Enter event name"
              onChange={(event) => handleOnEventNameChange(event.target.value, idx)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamEventCreation;
