import React from "react";
import "./TeamCard.css";

interface IProps {
  team: any;
  selectedTeamId?: string;
  callback: Function;
}

const TeamCard: React.FC<IProps> = ({ team, selectedTeamId, callback }) => {
  return (
    <div
      className={`team-card ${
        selectedTeamId === team.teamId ? "team-card--selected" : ""
      }`}
      onClick={() => callback(team)}
    >
      <div className="team-card__info">
        <div key={team.teamId + team.teamName} className="team-card__info--name">
          {team.teamName}
        </div>
        <div className="team-card__info--members">
          {team.memberships.map((member) => (
            <span key={team.teamId + member.username}>{member.username} | </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
