import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../Components/Header/Header'
import './LandingPage.css'

const LandingPage: React.FC = () => {

    return (
        <div className="landing-page em-l-container">
           <Header />
           <div className="landing-page--content">
                <h2>Manage your team’s calendar in one place</h2>
                <div className="calendar-img"><img alt="calendar-gif" src={calendargif} /></div>
                <Link to="/calendar"><button className="em-c-btn em-c-btn--primary" >Let’s get started</button></Link>
           </div>
           <div className="landing-page--footer">
                Brought to you by <span className="team-name">DDS-Web Development &amp; Design</span> <span className="mini-heart">❤</span>
           </div>
        </div>

    )
}

const calendargif = require('../../Assets/images/teamcalendar.gif')

export default LandingPage
