import store from '../../Store/store'

class UserService {
    static async getUsersAsync(search: string, signal: AbortSignal) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/SearchUser?search=${search}`,
            {
                method: 'GET',
                mode: 'cors',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        if (response.status !== 200) { throw response }
        return await response.json()
    }

    static async submitFeedback(comment:string) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/SubmitFeedback`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({comment})
            }
        )
        if (response.status !== 200) { throw response }
        return 'Successfully submitted feedback'
    }
}

export default UserService