import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { isDateInRange, isWeekend } from "../../Utilities/DateUtility";
import {
  updateIsShowingEventDetail,
  updateIsShowingEventForm,
} from "../../Store/Actions/app";
import "./TeamTable.css";
import { checkIfLogonUserIsOwnerOrAdmin } from "../../Utilities/RoleUtility";
import Tooltip from "../Tooltip/Tooltip";

interface IProps {
  team: any;
  dateSelectCallback: Function;
  userSelectCallback?: Function;
  teamSelectCallback?: Function;
  eventSelectCallback: Function;
}

const TeamTable: React.FC<IProps> = ({
  team,
  dateSelectCallback,
  userSelectCallback,
  teamSelectCallback,
  eventSelectCallback,
}) => {
  const dispatch = useDispatch();
  const currMonth = useSelector((state: any) => state.app.currMonth);
  const daysInMonth = useSelector((state: any) => state.app.daysInMonth);
  const logonUserId = useSelector((state: any) => state.user.userId);

  const checkIfWeekend = (idx: number) => {
    return isWeekend(moment(currMonth).set("date", idx + 1)) ? "weekend" : "";
  };

  const checkIfTeamIconDifferent = (event: any) => {
    if (event.eventTeamId && team.teamName !== "Owner") {
      if (event.eventTeamId !== team.teamId) {
        return `${event.eventIcon} different-team-icon`;
      }
      return event.eventIcon;
    } else {
      return event.eventIcon;
    }
  };

  const displayEvent = (user: any, date: any) => {
    let currEvent = user.events?.find((item) => {
      return isDateInRange(date + 1, currMonth, item.startDate, item.endDate);
    });
    if (currEvent) {
      return (
        <Tooltip
          text={`${currEvent.eventName}`}
          key={`${user.userId}-${date}`}
          optionalClass="date-border"
        >
          <div
            className={`dates-input type-icon ${checkIfWeekend(date)}
            ${checkIfLogonUserIsOwnerOrAdmin(team) ? "" : "disbled"}
            ${checkIfTeamIconDifferent(currEvent)} ${currEvent.oneDayChoice}`}
            key={`${currEvent.eventName}-${date}`}
            onClick={() => {
              eventSelectCallback &&
                eventSelectCallback({
                  user,
                  event: { ...currEvent },
                });
              teamSelectCallback && teamSelectCallback(team);
              dispatch(updateIsShowingEventDetail(true));
            }}
          />
        </Tooltip>
      );
    } else if (
      user.userId === logonUserId ||
      checkIfLogonUserIsOwnerOrAdmin(team)
    ) {
      return (
        <div
          key={`${user.userId}-${date}`}
          className={`dates-input date-border empty-event ${checkIfWeekend(
            date
          )}
          ${checkIfLogonUserIsOwnerOrAdmin(team) ? "" : "disbled"}`}
          onClick={() => {
            let currDate = moment(currMonth)
              .set("date", date + 1)
              .startOf("date")
              .toDate();
            dateSelectCallback(currDate);
            teamSelectCallback && teamSelectCallback(team);
            userSelectCallback && userSelectCallback(user.userId);
            dispatch(updateIsShowingEventForm(true));
          }}
        />
      );
    } else {
      return (
        <div
          key={`${user.userId}-${date}`}
          className={`dates-input date-border ${checkIfWeekend(date)} 
          ${checkIfLogonUserIsOwnerOrAdmin(team) ? "" : "disbled"}`}
        />
      );
    }
  };

  return (
    <div className="team-table">
      <div className="team-table_team-name">
        {team.teamName !== "Owner"
          ? `${team.teamName.toLocaleUpperCase()} TEAM`
          : team.teamName.toLocaleUpperCase()}
      </div>
      {team.memberships &&
        team.memberships
          .filter(
            (user) => user.userId !== logonUserId || team.teamName === "Owner"
          )
          .sort((a, b) => (a.subteam > b.subteam ? 1 : -1))
          .map((member) => (
            <div
              key={`${team.teamName}-${member.userId}`}
              className="team-table_record"
            >
              <div className="employee">
                {member.username}
                <span className="employee--subteam">{member.subteam}</span>
              </div>
              <div className="dates">
                {[...Array(daysInMonth)].map((e, i) => displayEvent(member, i))}
              </div>
            </div>
          ))}
    </div>
  );
};

export default TeamTable;
