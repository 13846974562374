import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
        authority: 'https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com/',
        redirectUri: "/",
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
};

export const loginRequest: RedirectRequest = {
    scopes: [`${process.env.REACT_APP_SCOPES}`]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};