import { IIcon } from '../Reducers/app'

interface IState {
    teamSearchingList: Array<ITeam> | null
    selectedTeam: ITeam | null
    isTeamNotFound: boolean
    ownTeams: Array<ITeam> | null
    additionalTeams: Array<ITeam> | null
}

interface ITeam {
    teamId: string,
    teamName: string,
    memberships: Array<IMember>,
    ownerId: string,
    isSearchable: boolean,
    icons: Array<IIcon>,
}

interface IMember {
    userId: string
    username: string
    teamId: string
    subteam: string
    roleId: number
}

const initState: IState = {
    teamSearchingList: null,
    selectedTeam: null,
    isTeamNotFound: false,
    ownTeams: null,
    additionalTeams: null,
}

export default (state: IState = initState, action: any) => {
    switch (action.type) {
        case 'UPDATE_TEAMS':
            return { ...state, ownTeams: action.payload.ownTeams, additionalTeams: action.payload.additionalTeams }
        case 'UPDATE_TEAM_MEMBERS':
            return { ...state, selectedTeam: { ...state.selectedTeam, memberships: action.payload } }
        case 'UPDATE_SELECTED_TEAM':
            if (action.payload) {
                let sortedMember = action.payload?.memberships.sort((a, b) => a.subteam > b.subteam ? 1 : -1)
                return { ...state, selectedTeam: { ...action.payload, memberships: sortedMember} }
            } else {
                return { ...state, selectedTeam: action.payload }
            }
        case 'UPDATE_TEAM_LIST':
            return { ...state, teamSearchingList: action.payload }
        case 'UPDATE_IS_TEAM_NOT_FOUND':
            return { ...state, isTeamNotFound: action.payload }
        case 'CLEAR_TEAM_LIST':
            return { ...state, teamSearchingList: null, isTeamNotFound: false }
        case 'CLEAR_STORE':
            return initState
        default:
            return state
    }
}
