
import store from '../../Store/store'

export default class TeamServices {
    static async getTeams() {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/GetLogonUserTeams`,
            {
                method:'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                },
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async getTeamById(teamId:string) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/GetTeamById?teamId=${teamId}`,
            {
                method:'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                },
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async updateTeamInfo(payload: any) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/UpdateTeam`,
            {
                method:'PUT',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async addAdditionalTeam(teamId: string, userId:string) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/AddAdditionalTeam`,
            {
                method:'POST',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ teamId, userId })
            }
        )
        if(response.status !== 200){ throw response }
        return await response.json()
    }

    static async removeOwnTeam(teamId:string) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/RemoveOwnTeam?teamId=${teamId}`,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        if (response.status !== 200) { throw response }
        return await response.json()
    }

    static async removeAdditionalTeam(teamId:string, userId:string) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/RemoveAdditionalTeam?teamId=${teamId}&userId=${userId}`,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        if (response.status !== 200) { throw response }
        return await response.json()
    }

    static async searchTeamAsync(search:string, signal: AbortSignal) {
        const token = store.getState().user.token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/SearchTeam?search=${search}`,
            {
                method: 'GET',
                mode: 'cors',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        if (response.status !== 200) { throw response }
        return await response.json()
    }
}