import React from "react";
import { createRoot } from "react-dom/client";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./Store/store";
import "./index.css";
import "./Assets/standard-2.0.2/css/em-standard-2.0.2.css";
import App from "./App";

import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </Provider>
);

serviceWorker.unregister();
