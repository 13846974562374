import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitFeedback } from "../../Store/Actions/app";
import Button from "../Button/Button";
import './FeedbackForm.css'

interface Props {
    onClose: Function;
}

const FeedbackForm: React.FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch();
    const userName = useSelector((state: any) => state.user.username);
    const [feedbackInput, setFeedbackInput] = useState("");
    const isBtnLoading = useSelector((state: any) => state.app.isBtnLoading)
    
    const submitButtonCallback = () => {
        setFeedbackInput("")
        dispatch(submitFeedback(feedbackInput))
    };
    return (
        <div className="overlay">
            <div className="feedback-form">
                <div className="em-u-font-size-med-3 em-u-font-style-semibold em-u-margin-bottom">
                    Send us your suggestions
                </div>
                <div>
                    <div className="em-c-field em-is-readonly">
                        <label className="em-c-field__label">Name</label>
                        <div className="em-c-field__body">
                            <input
                                className="em-c-input em-js-input"
                                value={userName}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="em-u-font-size-med em-u-font-style-semibold">
                        Let’s talk
                    </div>
                    <textarea
                        className="em-u-margin-top"
                        placeholder="Tell us what you are thinking"
                        rows={6}
                        value={feedbackInput}
                        onChange={(e) =>setFeedbackInput(e.target.value)}
                        disabled={isBtnLoading}
                    />
                </div>
                <div className="em-u-display-flex em-u-margin-top">
                    <Button
                        optionalClass={`em-c-btn--primary ${feedbackInput.length === 0  ? 'em-c-btn--disabled' : ''}`}
                        name="Submit"
                        callback={() =>
                            feedbackInput.length > 0
                                ? submitButtonCallback()
                                : {}
                        }
                        loading={isBtnLoading}
                    />
                    <Button
                        optionalClass="em-u-margin-left em-c-btn em-c-btn--white"
                        name="Cancel"
                        callback={onClose}
                    />
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;
