import moment from 'moment'

export const isWeekend = (date) => {
    return date.day() === 0 || date.day() === 6 
}

export const isDateInRange = (day, month, startDate, endDate) => {
    let date = moment(month).utc().set('date', day).toISOString().split('T')[0]
    let start = startDate?.split('T')[0]
    let end = endDate?.split('T')[0]
    return date >= start && date <= end
}