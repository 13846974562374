import React from "react";
import "./Button.css";

interface IProps {
  id: string;
  firstBtnName: string;
  firstBtnCallback: Function;
  secondBtnName: string;
  secondBtnCallback: Function;
  value: boolean;
  isDisabled?: boolean;
  note?: string;
  optionalClass?: string;
}

const Toggle: React.FC<IProps> = ({
  id,
  firstBtnName,
  firstBtnCallback,
  secondBtnName,
  secondBtnCallback,
  value,
  note,
  isDisabled,
  optionalClass
}) => {
  return (
    <div className={`em-c-field em-c-field--toggle ${optionalClass}`}>
      <div className="em-c-field__body">
        <div className="em-c-toggle">
          <input
            className="em-c-toggle__input em-u-is-vishidden"
            type="radio"
            id={`${id}-${firstBtnName}`}
            name={`${id}-${firstBtnName}`}
            checked={value}
            disabled={isDisabled}
            onChange={() => firstBtnCallback(!value)}
          />
          <label
            className={`em-c-toggle__label ${
              isDisabled ? "em-is-disabled" : ""
            }`}
            htmlFor={`${id}-${firstBtnName}`}
          >
            {firstBtnName}
          </label>
          <input
            className="em-c-toggle__input em-u-is-vishidden"
            type="radio"
            id={`${id}-${secondBtnName}`}
            name={`${id}-${secondBtnName}`}
            checked={!value}
            disabled={isDisabled}
            onChange={() => secondBtnCallback(!value)}
          />
          <label
            className={`em-c-toggle__label ${
              isDisabled ? "em-is-disabled" : ""
            }`}
            htmlFor={`${id}-${secondBtnName}`}
          >
            {secondBtnName}
          </label>
        </div>
        <div className="em-c-field__note">{note}</div>
      </div>
    </div>
  );
};

export default Toggle;
