import store from "../Store/store";

export const checkIfLogonUserIsOwnerOrAdmin = (team: any) => {
  const logonUserId = store.getState().user.userId;
  let isAdmin = team.memberships.find(
    (member) =>
      member.userId === logonUserId &&
      (member.roleId === 1 || member.roleId === 2)
  );
  return isAdmin;
};