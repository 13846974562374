import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { removeEvent } from '../../Store/Actions/event'
import { updateIsBtnLoading } from '../../Store/Actions/app'
import Button from '../Button/Button'
import { appInsights } from '../../App'
import './EventDetail.css'
import { checkIfLogonUserIsOwnerOrAdmin } from '../../Utilities/RoleUtility'

interface IProps {
    eventDetail: any
    setSelectedEventCallback: Function
    team: any
}

const EventDetail: React.FC<IProps> = ({ eventDetail, setSelectedEventCallback, team }) => {
    const dispatch = useDispatch()
    const logonUserId = useSelector((state:any) => state.user.userId)
    const isBtnLoading = useSelector((state:any) => state.app.isBtnLoading)

    const cancelEvent = (eventId:string) => {
        appInsights.trackEvent({name: 'deleteRecord'})
        appInsights.startTrackEvent('deleteRecordDuration')
        dispatch(updateIsBtnLoading(true))
        dispatch(removeEvent(eventId))
    }

    return (
        <div className="overlay">
            <div className="event-detail">
                <h3>Event Detail</h3>
                <div className="event-detail--content">
                    <div>
                        Event name: {eventDetail.event.eventName}
                    </div>
                    <div>
                        Date: {moment(eventDetail.event.startDate).isSame(moment(eventDetail.event.endDate), 'day') ? 
                        `${moment(eventDetail.event.startDate.split('T')[0]).format('L')}`
                        : `${moment(eventDetail.event.startDate.split('T')[0]).format('L')} - ${moment(eventDetail.event.endDate.split('T')[0]).format('L')}`}
                    </div>
                    <div>Remark: {eventDetail.event.remark ? eventDetail.event.remark : '-'}</div>
                    <div>
                        Submitted by {eventDetail.user.username}
                    </div>
                </div>
                <div className="button-wrapper">
                    {(eventDetail.user.userId === logonUserId || checkIfLogonUserIsOwnerOrAdmin(team)) &&
                    <Button
                        name="Delete record"
                        optionalClass="em-c-btn--primary em-u-margin-right"
                        callback={() => cancelEvent(eventDetail.event.eventId)}
                        hasLoading={true}
                        loading={isBtnLoading}
                    />
                    }
                    <button className="em-c-btn em-c-btn--white" onClick={() => setSelectedEventCallback(null)}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default EventDetail
