import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import avatar from "../../Assets/images/default-avatar-large.svg"
import icons  from "../../Assets/standard-2.0.2/images/24/em-icons.svg"
import './SideBar.css'
import { globalEvents } from '../../Utilities/ConstEvents'

const Sidebar: React.FC = () => {
    const user = useSelector((state: any) => state.user)
    const userLanId = useSelector((state: any) => state.user.userLanId)
    const [photoError, setPhotoError] = useState(false)

    const getGreetingWord = () => {
        let currTime = moment()
        if(currTime.isBetween(moment('05:00:00', "hh:mm:ss"), moment('11:59:59', "hh:mm:ss"))) 
            return "Good Morning!"
        return "Good Evening!"
    }

    return (
        <div className="side-bar">
            <div className="side-bar--header">
                <div className="avatar">
                    <img 
                        alt='avatar' 
                        src={
                            userLanId && !photoError
                              ? `https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/${userLanId.replace(
                                  /\\/g,
                                  "_"
                                )}_LThumb.jpg`
                              : avatar
                        }
                        onError={() => setPhotoError(true)}
                    />
                </div>
                <h3>{getGreetingWord()}</h3>
                <div className="side-bar--header__name">{user.username}</div>
            </div>
            <div className="side-bar--icon-explanation">
                {globalEvents.map(item =>
                    <div key={`icon-${item.eventIcon}`} className='icon-des'>
                    <div className={`type-icon-sidebar ${item.eventIcon}`} />
                        {item.eventName}
                    </div>
                )}
            </div>
            <div className="side-bar--action-btns">
                <div className="icon-btn-wrapper">
                    <svg className="em-c-icon em-c-icon--small">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" href={`${icons}#group`}></use>
                    </svg>
                    <Link to="/teams"><button className="em-c-btn">Manage Team</button></Link>
                </div>
                <div className="icon-btn-wrapper">
                    <svg className="em-c-icon em-c-icon--small">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" href={`${icons}#calendar`}></use>
                    </svg>
                    <Link to="/calendar"><button className="em-c-btn">Calendar view</button></Link>
                </div>
            </div>
        </div>
    )
}


export default Sidebar
