import React from "react";
import icons from "../../Assets/standard-2.0.2/images/24/em-icons.svg";

interface IProps {
  icon: string;
  optionalClass?: string;
  callback?: Function;
  disabled?: boolean
}

const Icon: React.FC<IProps> = ({ icon, optionalClass, callback, disabled }) => {
  return (
    <svg
      className={`${optionalClass} ${disabled ? "icon-disabled" : ""}`}
      onClick={() => (callback && !disabled ? callback() : null)}
    >
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        href={`${icons}#${icon}`}
      ></use>
    </svg>
  );
};

export default Icon;
