import TeamServices from "../../Services/TeamServices/TeamService"
import store from '../store'
import { appInsights } from '../../App'
import { updateErrMessage, updateIsBtnLoading, updateIsPageLoading } from "./app"
import { RoleTypes } from "../../Utilities/ConstRoles"

let controller: AbortController
let signal: AbortSignal

export const getTeams = () => {
    return (dispatch: any) => {
        dispatch(updateIsPageLoading(true))
        const request = TeamServices.getTeams()
        request.then((response: any) => {
            dispatch({ type: 'UPDATE_TEAMS', payload: response })
            dispatch(updateIsPageLoading(false))
            dispatch(updateIsBtnLoading(false))
        }).catch(async (error) => {
            const response = await error.json()
            dispatch(updateIsPageLoading(false))
            dispatch(updateErrMessage(response.message))
        })
    }
}

export const getTeamDetail = (teamId: string) => {
    return (dispatch: any) => {
        const request = TeamServices.getTeamById(teamId)
        request.then((response: any) => {
            dispatch({ type: 'UPDATE_SELECTED_TEAM', payload: response })
            dispatch(updateIsPageLoading(false))
        }).catch(async (error) => {
            const response = await error.json()
            dispatch(updateIsPageLoading(false))
            dispatch(updateErrMessage(response.message))
        })
    }
}

export const removeTeam = (team: any, type: string) => {
    return (dispatch: any) => {
        let request
        if (type === "Own") {
            request = TeamServices.removeOwnTeam(team.teamId)
        } else {
            const userId = store.getState().user.userId
            request = TeamServices.removeAdditionalTeam(team.teamId, userId)
        }
        request.then((response: any) => {
            dispatch(updateIsBtnLoading(false))
            dispatch(getTeams())
            appInsights.stopTrackEvent('deleteTeamDuration')
        }).catch(async (error) => {
            const response = await error.json()
            dispatch(updateIsBtnLoading(false))
            dispatch(updateErrMessage(response.message))
            appInsights.stopTrackEvent('deleteTeamDuration')
        })
    }
}


export const newEmptyTeam = () => (dispatch: any) => {
    dispatch({
        type: 'UPDATE_SELECTED_TEAM', payload: {
            id: null,
            teamName: '',
            memberships: [],
            ownerId: store.getState().user.userId,
            isSearchable: true
        }
    })
}

export const addSelectedMember = () => (dispatch: any) => {
    if (!store.getState().team.selectedTeam) { dispatch(newEmptyTeam()) }
    const user = store.getState().user.selectedUser
    const team = store.getState().team.selectedTeam
    const newUser = {
        subteam: user.subteam,
        teamId: team.teamId,
        userId: user.id,
        username: user.name,
        roleId: user.roleId
    }
    dispatch({ type: 'UPDATE_TEAM_MEMBERS', payload: [...store.getState().team.selectedTeam.memberships, newUser] })
}

export const removeMember = (user: any) => {
    return (dispatch: any) => {
        let memberships = store.getState().team.selectedTeam.memberships
        memberships = memberships.filter(item => item !== user)
        dispatch({ type: 'UPDATE_TEAM_MEMBERS', payload: memberships })
    }
}

export const addAdditionalTeam = (teamId:string, navigate:any) => {
    return (dispatch: any) => {
        const userId = store.getState().user.userId
        const request = TeamServices.addAdditionalTeam(teamId, userId)
        request.then((response: any) => {
            dispatch(updateIsBtnLoading(false))
            appInsights.stopTrackEvent('addTeamDuration')
            navigate('/teams')
        }).catch(async (error) => {
            const response = await error.json()
            dispatch(updateIsBtnLoading(false))
            dispatch(updateErrMessage(response.message))
            appInsights.stopTrackEvent('addTeamDuration')
        })
    }
}

export const updateTeam = (teamName: string, isSearchable: boolean, modifiedMembers: any, icons: any, navigate?: any) => {
    return (dispatch: any) => {
        const team = store.getState().team.selectedTeam
        const userId = store.getState().user.userId
        let currentOwner = modifiedMembers.find((member:any) => member.roleId === RoleTypes.Owner)
        if (currentOwner !== undefined && currentOwner.userId !== team.ownerId) {
            team.ownerId = currentOwner.userId
        }
        const payload = {
            teamId: team.teamId || null,
            teamName,
            ownerId: team.ownerId ? team.ownerId : userId,
            memberships: modifiedMembers,
            isSearchable,
            icons: icons,
        }
        const request = TeamServices.updateTeamInfo(payload)
        request.then((response: any) => {
            dispatch((updateSelectedTeam(response)))
            dispatch(updateIsBtnLoading(false))
            if (payload.teamId === null) {
                appInsights.stopTrackEvent('createNewTeamDuration')
            } else {
                appInsights.stopTrackEvent('updateTeamDuration')
            }
            navigate(`/teams`)
        }).catch(async (error) => {
            const response = await error.json()
            dispatch(updateIsBtnLoading(false))
            dispatch(updateErrMessage(response.message))
            appInsights.stopTrackEvent('createTeamDuration')
        })
    }
}

export const updateTeamFilter = (newTeam: any) => (dispatch: any) => {
    dispatch({ type: "UPDATE_TEAM", payload: newTeam })
}

export const updateMemberships = (newMemberships: any) => (dispatch: any) => {
    dispatch({ type: "UPDATE_TEAM_MEMBERS", payload: newMemberships })
}

export const updateTeamNotFound = (value: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: 'UPDATE_IS_TEAM_NOT_FOUND', payload: value })
    }
}

export const updateTeamSearchingList = (value: string) => {
    if (controller !== undefined) {
        controller.abort();
    }
    controller = new AbortController()
    signal = controller.signal
    const request = TeamServices.searchTeamAsync(value, signal)
    return (dispatch: any) => {
        request.then((response: any) => {
            if (response.length !== 0) {
                dispatch({ type: 'UPDATE_TEAM_LIST', payload: response })
                dispatch(updateTeamNotFound(false))
            } else {
                dispatch({ type: 'UPDATE_TEAM_LIST', payload: [] })
                dispatch(updateTeamNotFound(true))
            }
        }).catch((error) => {
        })
    }
}

export const updateSelectedTeam = (team: any) => {
    return (dispatch: any) => {
        dispatch({ type: 'UPDATE_SELECTED_TEAM', payload: team })
    }
}

export const clearTeamSearchingList = () => {
    return (dispatch: any) => {
        dispatch({ type: 'CLEAR_TEAM_LIST' })
    }
}
