import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { updateErrMessage } from "./Store/Actions/app";
import Calendar from "./Pages/Calendar/Calendar";
import Sidebar from "./Pages/SideBar/SideBar";
import TeamManagement from "./Pages/TeamManagement/TeamManagement";
import LandingPage from "./Pages/LandingPage/LandingPage";
import TeamAddingForm from "./Components/TeamForm/TeamAddingForm";
import TeamCreatingForm from "./Components/TeamForm/TeamCreatingForm";
import AlertMessage from "./Components/AlertMessage/AlertMessage";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { updateLogonUser } from "./Store/Actions/user";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import LoadingIndicator from "./Components/LoadingIndicator/LoadingIndicator";
import "./App.css";

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHT,
    disableFetchTracking: false,
  },
});

const App = () => {
  const dispatch = useDispatch();
  const errMessage = useSelector((state: any) => state.app.errMessage);
  const { userId, token } = useSelector((statue: any) => statue.user);
  const { instance, accounts } = useMsal();

  const getAccessToken = () => {
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_SCOPES}`],
      account: accounts[0],
    };

    if (accounts.length > 0) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          dispatch(
            updateLogonUser({
              accessToken: response.accessToken,
              idTokenClaims: response.account?.idTokenClaims,
            })
          );
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenRedirect(accessTokenRequest)
              .catch((error) => console.log(error));
          }
        });
    }
  };

  useEffect(() => {
    getAccessToken();
    appInsights.loadAppInsights();
    console.log(
      "%c Made with ❤ by DDS-Web Dev & Design",
      "font-size: 25px;color: palevioletred; text-shadow: 1px 1px 0 rgb(217,31,38) , 2px 2px 0 rgb(5,148,68) , 3px 3px 0 rgb(2,135,206) , 4px 4px 0 rgb(4,77,145)"
    );
  }, []);

  useEffect(() => {
    if (userId !== "") {
      appInsights.setAuthenticatedUserContext(userId);
    }
  }, [userId]);

  return (
    <div className="app">
      {token == "" ? (
        <LoadingIndicator optionalClass="em-u-margin-left-double"/>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route index element={<LandingPage />} />
            <Route path="/*" element={<Sidebar />} />
          </Routes>
          <div className="content">
            <Routes>
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/teams" element={<TeamManagement />} />
              <Route
                path="/teams/manage/:teamId"
                element={<TeamCreatingForm />}
              />
              <Route
                path="/teams/detail/:teamId"
                element={<TeamAddingForm />}
              />
              <Route path="/teams/add" element={<TeamAddingForm />} />
              <Route path="/teams/new" element={<TeamCreatingForm />} />
            </Routes>
          </div>
        </BrowserRouter>
      )}
      {errMessage ? (
        <AlertMessage
          message={errMessage}
          firstBtnName="Close"
          firstBtnCallback={() => dispatch(updateErrMessage(null))}
        />
      ) : null}
    </div>
  );
};

export default App;
