import React from 'react'
import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import './AlertMessage.css'

interface IProps {
    message: string
    subMessage?: string
    firstBtnName: string
    firstBtnCallback: Function
    secondBtnName?: string
    secondBtnCallback?: Function
}

const AlertMessage: React.FC<IProps> = ({ message, subMessage, firstBtnName, firstBtnCallback, secondBtnName, secondBtnCallback }) => {
    const isBtnLoading = useSelector((state: any) => state.app.isBtnLoading)

    return (
        <div className="overlay">
            <div className="alert-message">
                <div className="message">{message}</div>
                {subMessage && <div className="sub-message">{subMessage}</div>}
                <div className="button-wrapper">
                    <Button
                        name={firstBtnName}
                        optionalClass="em-c-btn--primary em-u-margin-right"
                        callback={firstBtnCallback}
                        hasLoading={true}
                        loading={isBtnLoading}
                    />
                    {secondBtnName && secondBtnCallback ?
                    <button className="em-c-btn em-c-btn--white" onClick={() => secondBtnCallback()}>{secondBtnName}</button>
                    : null}
                </div>
            </div>
        </div>
    )
}

export default AlertMessage
