import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  updateIsBtnLoading,
  updateIsPageLoading,
} from "../../Store/Actions/app";
import {
  clearTeamSearchingList,
  updateTeamSearchingList,
  updateSelectedTeam,
  addAdditionalTeam,
  getTeamDetail,
} from "../../Store/Actions/team";
import Button from "../Button/Button";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import TeamCard from "../TeamCard/TeamCard";
import { appInsights } from "../../App";
import "./TeamForm.css";
import { RoleTypes } from "../../Utilities/ConstRoles";

const TeamAddingForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [teamNameInput, setTeamNameInput] = useState("");
  const teamSearchingList = useSelector(
    (state: any) => state.team.teamSearchingList
  );
  const selectedTeam = useSelector((state: any) => state.team.selectedTeam);
  const isTeamNotFound = useSelector((state: any) => state.team.isTeamNotFound);
  const isBtnLoading = useSelector((state: any) => state.app.isBtnLoading);
  const isPageLoading = useSelector((state: any) => state.app.isPageLoading);

  const teamNameInputCallback = (value: string) => {
    dispatch(clearTeamSearchingList());
    setTeamNameInput(value);
    if (value.length >= 3) {
      setIsLoading(true);
      dispatch(updateTeamSearchingList(value));
    }
  };

  const addTeamCallback = () => {
    appInsights.trackEvent({ name: "addTeam" });
    appInsights.startTrackEvent("addTeamDuration");
    dispatch(updateIsBtnLoading(true));
    dispatch(addAdditionalTeam(selectedTeam.teamId, navigate));
    dispatch(clearTeamSearchingList());
    setTeamNameInput("");
  };

  const selectTeamCallback = (team) => {
    setTeamNameInput(team.teamName);
    dispatch(updateSelectedTeam(team));
    dispatch(clearTeamSearchingList());
  };

  useEffect(() => {
    if (teamId && !selectedTeam) {
      dispatch(updateIsPageLoading(true));
      dispatch(getTeamDetail(teamId));
    }
  }, [teamId]);

  useEffect(() => {
    setIsLoading(false);
  }, [teamSearchingList]);

  useEffect(() => {
    if (selectedTeam && selectedTeam.teamName !== "") {
      setTeamNameInput(selectedTeam.teamName);
    }
  }, [selectedTeam]);

  useEffect(() => {
    appInsights.trackPageView({ name: "userAccessAddTeamView" });
    dispatch(clearTeamSearchingList());
  }, []);

  return (
    <div className="app-content team-form">
      <h3>
        Manage Team <span className="sub-title">| Add another team</span>
      </h3>
      <hr />
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="team-form--content">
          <h5>Team Name</h5>
          <div className="em-c-table-object__header">
            <div className="em-c-search__body">
              <input
                type="search"
                id="search"
                className="em-c-search__input"
                placeholder="Find other teams"
                value={teamNameInput}
                onChange={(e) => teamNameInputCallback(e.target.value)}
                disabled={teamId ? true : false}
              />
              {isLoading ? (
                <LoadingIcon optionalClass="member-form__body--search-loading" />
              ) : null}
            </div>
          </div>
          {teamNameInput.length >= 3 && selectedTeam === null ? (
            isTeamNotFound ? (
              <div className="member-form__user-picker no-data">
                We could not find the team in the system.
              </div>
            ) : (
              <div className="member-form__user-picker">
                <div className="member-form__user-picker--list">
                  {teamSearchingList &&
                    teamSearchingList.map((team, index) => (
                      <TeamCard
                        key={team.teamId + index}
                        team={team}
                        callback={() => selectTeamCallback(team)}
                      />
                    ))}
                </div>
              </div>
            )
          ) : null}
          <br />
          <br />
          <h5>Membership</h5>
          <table className="em-c-table em-u-margin-bottom-double">
            <thead className="em-c-table__header">
              <tr className="em-c-table__header-row">
                <th scope="col" className="em-c-table__header-cell">
                  Name
                </th>
                <th scope="col" className="em-c-table__header-cell">
                  Roles
                </th>
                <th scope="col" className="em-c-table__header-cell">
                  Sub Team
                </th>
              </tr>
            </thead>
            <tbody className="em-c-table__body ">
              {selectedTeam &&
                selectedTeam.memberships.map((member) => (
                  <tr key={member.userId} className="em-c-table__row ">
                    <td className="em-c-table__cell em-js-cell">
                      {member.username}
                    </td>
                    <td className="em-c-table__cell em-js-cell">
                      {" "}
                      {RoleTypes[member.roleId]}{" "}
                    </td>
                    <td className="em-c-table__cell em-js-cell">
                      {member.subteam === "" ? "-" : member.subteam}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot className="em-c-table__footer">
              <tr className="em-c-table__footer-row"></tr>
            </tfoot>
          </table>
          <div className="button-wrapper">
            {teamId ? null : (
              <Button
                name="Add"
                optionalClass="em-c-btn--primary em-u-margin-right"
                callback={() => addTeamCallback()}
                hasLoading={true}
                loading={isBtnLoading}
              />
            )}
            <Link to="/teams">
              <button className="em-c-btn em-c-btn--white">Back</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamAddingForm;
