interface IState {
    userId: string
    username: string
    userEmail: string
    userLanId?: string | null
    token: string
    events: Array<any> | null
    userList: Array<any> | null
    selectedUser: any | null
    isUserNotFound: boolean
}

const initState: IState = {
    userId: '',
    username: '',
    userEmail: '',
    userLanId: '',
    token: '',
    events: null,
    userList: null,
    selectedUser: null,
    isUserNotFound: false,
}

export default (state: IState = initState, action: any) => {
    switch (action.type) {
        case 'UPDATE_LOGON_USER':
            const userInfo = action.payload.idTokenClaims
            return { ...state, userId: userInfo.oid, username: userInfo.name, userEmail: userInfo.preferred_username, token: action.payload.accessToken }
        case 'UPDATE_LOGON_USER_EVENTS':
            return { ...state, events: action.payload }
        case 'UPDATE_LOGON_USER_LANID':
            return { ...state, userLanId: action.payload }
        case 'UPDATE_USER_LIST':
            return { ...state, userList: action.payload }
        case 'UPDATE_SELECTED_USER':
            return { ...state, selectedUser: action.payload }
        case 'UPDATE_IS_USER_NOT_FOUND':
            return { ...state, isUserNotFound: action.payload }
        case 'CLEAR_USER_LIST':
            return { ...state, userList: null, selectedUser: null, isUserNotFound: false }
        default:
            return state
    }
}
