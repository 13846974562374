export const globalEvents = [
  {
    eventId: 1,
    eventName: "Training",
    eventIcon: "training-icon",
  },

  {
    eventId: 2,
    eventName: "Business Trip",
    eventIcon: "business-trip-icon",
  },

  {
    eventId: 3,
    eventName: "Public Holiday",
    eventIcon: "public-holiday-icon",
  },

  {
    eventId: 4,
    eventName: "Sick",
    eventIcon: "sick-icon",
  },
  {
    eventId: 5,
    eventName: "Vacation",
    eventIcon: "vacation-icon",
  },

  {
    eventId: 6,
    eventName: "WFH",
    eventIcon: "wfh-icon",
  },

  {
    eventId: 7,
    eventName: "On-Call",
    eventIcon: "on-call-icon",
  },

  {
    eventId: 8,
    eventName: "Meeting",
    eventIcon: "meeting-icon",
  },
];

export const customEvents = [
  {
    eventId: null,
    eventName: "",
    eventIcon: "personal-leave-icon",
  },

  {
    eventId: null,
    eventName: "",
    eventIcon: "team-building-icon",
  },

  {
    eventId: null,
    eventName: "",
    eventIcon: "workshop-icon",
  },

  {
    eventId: null,
    eventName: "",
    eventIcon: "dispatcher-icon",
  },
];
