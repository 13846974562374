import moment from "moment";
import UserService from "../../Services/UserServices/UserService";
import { getEvents, getLogonUserEvents } from "./event";
import store from "../store";

export const updateCurrMonth = (value: Date) => (dispatch: any) => {
  setTimeout(() => {
    const currMonth = store.getState().app.currMonth;
    if (
      moment(currMonth).format("MM-YYYY") ===
      moment(value).format("MM-YYYY")
    ) {
      dispatch(getEvents(value));
      dispatch(getLogonUserEvents(value));
    }
  }, 500);
  dispatch({ type: "UPDATE_CURR_MONTH", payload: value });
};

export const submitFeedback = (comment: string) => (dispatch: any) => {
  dispatch(updateIsBtnLoading(true));
  const request = UserService.submitFeedback(comment);
  request
    .then((response: any) => {
      dispatch(updateIsBtnLoading(false));
      dispatch(updateIsShowingFeedbackForm(false));
      dispatch(updateErrMessage("Thank you for your feedback ʕ•́ᴥ•̀ʔっ"));
    })
    .catch(async (error) => {
      const response = await error.json();
      dispatch(updateIsBtnLoading(false));
      dispatch(updateIsShowingFeedbackForm(false));
      dispatch(updateErrMessage(response.message));
    });
};

export const updateIsShowingEventForm = (value: boolean) => (dispatch: any) => {
  dispatch({ type: "UPDATE_IS_SHOWING_EVENT_FORM", payload: value });
};

export const updateIsShowingFeedbackForm =
  (value: boolean) => (dispatch: any) => {
    dispatch({ type: "UPDATE_IS_SHOWING_FEEDBACK_FORM", payload: value });
  };

export const updateIsShowingEventDetail =
  (value: boolean) => (dispatch: any) => {
    dispatch({ type: "UPDATE_IS_SHOWING_EVENT_DETAIL", payload: value });
  };

export const updateIsBtnLoading = (value: boolean) => (dispatch: any) => {
  dispatch({ type: "UPDATE_IS_BUTTON_LOADING", payload: value });
};

export const updateIsPageLoading = (value: boolean) => (dispatch: any) => {
  dispatch({ type: "UPDATE_IS_PAGE_LOADING", payload: value });
};

export const updateErrMessage = (message: string | null) => (dispatch: any) => {
  dispatch({ type: "UPDATE_ERROR_MESSAGE", payload: message });
};
