import React from "react";
import "./UserCard.css";

interface IProps {
  user: any;
  callback: Function;
}

const UserCard: React.FC<IProps> = ({ user, callback }) => {
  return (
    <div className={`user-card`} onClick={() => callback(user)}>
      <div className="user-card__info">
        <div className="user-card__info--name">{user.name}</div>
        <div className="user-card__info--email">{user.email}</div>
      </div>
    </div>
  );
};

export default UserCard;
